// DEMO

// Icons demo
.text-white{
  color: #fff;
}
.font-icon-wrapper {
  text-align: center;
  border: $gray-200 solid 1px;
  @include border-radius($border-radius);
  margin: 0 0 10px;
  padding: 5px;

  &.font-icon-lg {
    float: left;
    padding: 10px;
    text-align: center;
    margin-right: 15px;
    min-width: 64px;

    i {
      font-size: $h1-font-size;
    }
  }

  &:hover {
    background: $gray-100;
    color: $primary;

    p {
      color: $gray-600;
    }
  }

  i {
    font-size: ($font-size-lg * 1.5);
  }

  p {
    color: $gray-500;
    font-size: ($font-size-sm / 1.2);
    margin: 5px 0 0;
  }
}

.btn-icon-vertical {
  min-width: 100px;
}

.card.mb-3 {
  margin-bottom: $grid-gutter-width !important;
}

// Image BG

.demo-image-bg {
  height: 350px;
  margin-bottom: $grid-gutter-width;
  background-size: 100%;
}

// Loaders wrapper

.loader-wrapper {
  width: 150px;
  height: 100px;
  @include float-left;
}

// Slick slider

.slider-item {
  background: $gray-300;
  @include border-radius($border-radius);
  color: $gray-600;
  font-size: 36px;
  padding: 0;
  position: relative;
  height: 150px;
  line-height: 150px;
  text-align: center;
  margin: 0 $layout-spacer-x;
  transition: all .2s;
}

.slick-center {
  .slider-item {
    background: $gray-500;
    color: $gray-700;
  }
}

// Image Crop

.after-img {
  max-width: 100%;
}

// custom css all

$green: #2aad68;

.form-control:focus{
  border-color:$green;
  box-shadow:none;
}
.bg-map{
  // background-image: url('../images/map.png');
  background-color: black;
  background-repeat: no-repeat;
  background-size: 100% 100%;  
  height: 100%;
  width: 100%;
}

.heightcalc{
  height: calc(100vh - 48px);
}
.w_25{
  width: 25%;
}
.loginpage{
  height: 100%;
  .applogoinverse{
    text-align: center;
    h3{
      color:#fff;
    }
  }
  .grey_bg{
    background-color: gray;
  }
  
  .app-login-box h4 div{
    color: $green;
    font-weight:600;
    margin-bottom: 10px;
  }
  .login_content{
    padding-top: 17px;
  }
  ::-webkit-input-placeholder { /* Edge */
    color: white;
  }
  
  :-ms-input-placeholder { /* Internet Explorer */
    color: white;
  }
  
  ::placeholder {
    color: white;
  }
  .ng-select .ng-select-container{
    color: white;
    background-color: gray;
}
}
.btn-primary{
  background: $green;
  border-color: $green;
}
.btn-primary:hover{
  background: $green;
  border-color: $green;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, 
.btn-primary:not(:disabled):not(.disabled).active:focus, 
.show > .btn-primary.dropdown-toggle:focus{
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled):active, 
.btn-primary:not(:disabled):not(.disabled).active, 
.show > .btn-primary.dropdown-toggle{
  background: $green;
  border-color: $green;
}

.topvalueContent{
  display: flex;
  align-items: center;
  .userIcon{
    height:30px;
    width: 30px;
    border-radius:50%;
    cursor: pointer;
    box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%), 0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
    img{
      width:100%;
      height:100%;
      border-radius:50%;
    }
  }
  .topValue{
    margin-right: 5px;
    font-weight: bold;
    font-size: 13px;
    color: #000;
    display: flex;
    align-items: center;
  }
  .blockType{
    margin-left: 10px;
    display: block;
    border-radius: 3px;
    background: #d1f3ff;
    color: #033C73;
    padding: 4px 8px;
    box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%), 0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
    span{
      margin-right: 3px;
    }
  }
  .blockType.totalType{
    background:  #008CFF;
    color:#fff;
  }
}

.app-sidebar{
  right: 0;
}
.closed-sidebar .app-header .app-header__content,
.closed-sidebar .app-main__outer{
  padding-left:0;
  padding-right: 80px;
}
.closed-sidebar .app-header .app-header__content{
  padding-left:30px;
} 
.app-header .app-header__content{
  padding-left: 20px;
  padding-right: 280px;
}
.app-main__outer{
  padding: 60px 280px 0 0px;
}
.app-main__inner{
  padding: 0;
}
.map_fullview{
  height: calc(100vh - 60px);
  width: 100%;
  display: block;
}
.logosrc{
  width: 100px;
  height: 30px;
  margin: auto;
  img{
    height: 100%;
  }
}
.main-content{
  position: relative;
  height: 100%;
  width: 100%;
}
table{
  border-spacing: 0;
  border-collapse: collapse;
}
.testOverflow{
  white-space: nowrap; 
  max-width: 150px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}
.operationList{
  position: fixed;
  overflow-y: auto;
  height: auto;
  max-height: 30%;
  top: 80px;
  left: 20px;
  border-collapse: collapse;
  z-index: 2;
  min-height: 200px;

  table{
    background-color: transparent;

    td{
      border: 1px solid rgba(255, 255, 255, 0.5);
      color: #FFF;
      background-color: rgba(0, 0, 0, 0.5);
      vertical-align: middle;
      padding:2px 5px;
    }
    .assignbox{
      display: flex;
      justify-content: left;
      align-items: center;
      .assignimg{
        margin-right: 5px;
        img{
          height: 40px;
          width: 40px;
          min-width: 40px;
          min-height: 40px;
          border-radius: 50%;
        }
      }
    }
    .actions{
      background-color: #fff;
      display: flex;
      height: 47px;
      align-items: center;
      span{
        margin-right: 5px;
        height: 32px;
        width: 32px;
        cursor: pointer;
        box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 20%);
        border-radius: 50%;
        transition: 0.5s;
        transform: scale(1);
        display:flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background-color: rgba(0, 0, 0, 0.8);
        img{
          height: 100%;
          width: 100%;
        }
        &:hover{
          transform: scale(1.05);
        }
        &:last-child{
          margin-right: 0;
        }
      }
      
    }
  }
}
.no-data {
  text-align: center;
}
.modal-header .close{
  z-index: 1024;
}
.sidebar_list .modal-content{
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.sidebar_list .modal-dialog {
    padding-right: 0 !important;
    height: 100%;
}
.sidebar_list .modal-dialog{
  margin: 0 auto;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
  max-width: 50%;
  width: 100%;
}
.sidebar_list.fade .modal-dialog {
  -webkit-transform: translate(25%, 0);
  transform: translate(25%, 0);
}

.sidebar_list.show .modal-dialog{
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.content_filter{
  display: flex;
  align-items: center;
  .filteroptselect{
    ul {
      padding-left: 0;
      li{
        list-style: none;
        display: inline-block;
        height: 57px;
        width: 57px;
        margin-right: 5px;
        opacity: 0.7;
        cursor: pointer;
        
        img{
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  .filtersearch{
    display: block;
    margin-left: auto;
  }
}
.landrop{
  float: right;
  position: relative;
  top: -8px;
  .dropdown-menu{
    transform: translate(-10px, 35px);
    width: 90px;
    max-width: 100px;
    min-width: auto;
  }
}
.paginations{
  padding-top: 10px;
  margin: auto;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
}

.listingdata{
  .card-header--title {
    position: relative;
    &::before{
      content: " ";
      position: absolute;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      background-color: #000;
      top: 0;
      bottom: 0;
      margin: auto 0;
      left: -10px;
    }
    span{
      margin-right: 5px;
    }
  }
  .abmbulancelist_content{
    ul{
      padding-left: 0;
      li{
        list-style: none;
        display:flex;
        align-items: center;
        margin-bottom: 10px;
        .left_data{
          display:flex;
          align-items: center;
          .amb_icon{
            height: 57px;
            width: 57px;
            min-width: 57px;
            min-height: 57px;
            border-radius: 50%;
            margin-right: 5px;
            img{
              height: 100%;
              width: 100%;
              border-radius: 50%;
            }
          }
          .amb_full_data{
            .amb_value{
              font-weight: 600;
            }
            span{
              font-size: 14px;
            }
          }
        }
        .right_data{
          margin-left: auto;

          .action_button{
            display:flex;
            align-items: center;

            a, button{
              display: block;
              height: 57px;
              width: 57px;
              border-radius: 50%;
              margin-right: 5px;
              cursor: pointer;
              background-color: rgba(0, 0, 0, 0.5);
              padding: 10px;
              font-size: 18px;
              font-weight: 600;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              transition: 0.5s;
              border:none;
              &:hover{
                background-color: rgba(0, 0, 0, 0.8);
              }
              img{
                height: 100%;
                width: 100%;
                border-radius:0;
              }
            }
            a.disabled, button.disabled{
              cursor: not-allowed;
              background-color: #dddddd;
              &:hover{
                cursor: not-allowed;
                background-color:#dddddd;
              }
            }
          }
        }
      }
    }
  }
}

.content_filter .filteroptselect ul li.activeFilter{
  opacity: 1;
}
.btn-link:hover,
.btn-link:focus, 
.btn-link.focus{
  text-decoration: none;
}
.card-header{
  button{
    span{
      color: #000;
      font-size: 16px;
      &:hover{
        text-decoration: none;
      }
      &:focus{
        text-decoration: none;
        outline: none;
      }
    }
  }
}

.sidebar_footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 0 25px;
  background-color: #fafbfc;
}
.form_content{
  .form_header{
    width: 100%;
    margin-top: -60px;
    display: block;
    .form_img{
      height: 100px;
      width: 100px;
      border-radius: 50%;
      padding:10px;
      -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
      -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
      box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
      background: #fff;
      margin: auto;
      margin-bottom: 10px;
      img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .form_default{
      h4{
        text-align: center;
      }
      
      
    }
  }
  .form_button{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    padding-top: 10px;
    button{
      margin-right: 5px;
      i{
        position: relative;
        top: 2px;
      }
    }
  }
  .form_body{
    padding-top: 15px;
    .form_stepper{
      .nav-tabs{
        border-bottom: none;
      }
      .nav-tabs .nav-item{
        max-width: 100px;
        text-align: center;
      }
      .nav-tabs .nav-link{
        border: none;
        .roundedselect{
          height: 35px;
          width: 35px;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          display: flex;
          color: #000;
          background: rgba(0,0,0,0.1);
          margin: auto;
          margin-bottom: 5px;
        }
        .stepmenu{
          font-size: 15px;
        }
      }
      .nav-tabs .nav-link.active, 
      .nav-tabs .nav-item.show .nav-link{
        .roundedselect{
          background: #2aad68;
          border-color: #2aad68;
          color: #fff;
        }
       
      }
      .typesLists{
        padding-top: 20px;
        float: left;
        width: 100%;
        .typeRounded{
          float: left;
          line-height: 75px;
          margin: 4px 4px 4px 35px;
          overflow: hidden;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background-color: #474443;
          color: #fff;

          input{
            position: absolute;
            top: -20px;
            visibility: hidden;
          }
          span{
            display: block;
            font-size: 14px;
            padding: 13px 0;
            text-align: center;
          }
        }
        
      }
    }
  }
}
.typeRounded input:checked + span{
  background-color: #959291;
  color: #fff;
}

.tabaction{
  padding-top: 20px;
  float: left;
  width: 100%;
  text-align: center;

  button{
    width: 100px;
    text-align: center;
    margin-right: 5px;

    i{
      position: relative;
      top: 2px;
    }
  }
}

.stafflisting_content{
  width: 100%;
  padding: 0 50px;

  .stafflitsbox{
    height: 133px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 5px;
    overflow: hidden;
    overflow-y: auto;

    ul{
        padding-left: 0;
        li{
          position: relative;
          padding: 3px 5px;
          list-style: none;
          cursor: pointer;
          margin-bottom: 5px;
          transition: 0.5s;
          display: flex;
          width: 100%;
          i{
            position: relative;
            top: 3px;
            margin-left: auto;
          }
          &:hover{
            background: #d1f3ff;
            color: #033C73;
          }
      }
    }
  }
}

.detailsForm{
  textarea{
    height: 100px;
  }
  .fastTrackbox{
    height: 100px;
    width: 100%;
    border:1px solid #ced4da;
    
    overflow: hidden;
    overflow-y: auto;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    border-radius: 0.25rem;

    .fastList{
      width: 100%;
      margin-bottom: 0;
    }
    input{
      position: absolute;
      top: -20px;
      visibility: hidden;
    }
    span{
      display: block;
      font-size: 14px;
      text-align: left;
      padding: 3px;
    }

    ul{
      padding-left:0;
      li{
        list-style-type: none;
  
      }
    } 
  }
}

.fastTrackbox input:checked + span{
  background-color: #959291;
  color: #fff;
}

.modal-header.actionHeader{
    display: block;
    width: 100%;
    .modal-title{
      float: left;
      display: flex;
      align-items: center;
      .titleImg{
        height: 60px;
        width: 60px;
        border-radius: 50%;
        padding: 10px;
        margin-right: 5px;
        background-color: rgba(0, 0, 0, 0.5);
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .row{
      margin: 0;
      width: 100%;
      align-items: center;
      padding-top: 5px;
    }
    .modal_action_button{
      width: 100%;
      text-align: right;
      button{
        height: 55px;
        width: 55px;
        border-radius: 50%;
        padding: 10px;
        margin-right: 5px;
        background-color: rgba(0, 0, 0, 0.7);
        img{
          height: 100%;
          width: 100%;
        }
      }
    }
}

.snapviewContent{
  .ambulanceHeading{
    display: flex;
    align-items: center;
    .ambulance_img{
      width: 150px;
      height: 150px;
      min-width: 150px;
      max-width: 150px;
      img{
        height: 100%;
        width: 100%;
      }
    }
    .ambulancename{
      padding-left: 10px;
      label{
        width: 100%;
        text-align: left;
        margin-bottom: 2px;
        font-weight: 600;
      }
    }
  }
  .notify{
    color: royalblue;
    margin-bottom: 5px;
  }
  .snapAllbutton{
    width: 100%;
    button{
      width: 100%;
      margin-top: 10px;
      // display: flex;
      // justify-content: center;
      // align-items: center;
    }
    input[type="button"]{
      width: 100%;
      margin-top: 10px;
    }
  }
  .liststaff{
    padding:5px 0;
    padding-top: 20px;
    ul{
      padding-left: 0;
      li{
        list-style: none;
        display: inline-block;
        width: 16.66%;
        text-align: center;
      }
    }
  }
  .nav-tabs{
    border: none;
  }
  .nav-tabs .nav-link{
    border: none;
    border-radius: 0.25rem;
  }
  .nav-tabs .nav-link.active, 
  .nav-tabs .nav-item.show .nav-link{
    background: #2aad68;
    border-color: #2aad68;
    color: #fff;
  }
}

.ambulanceViewdetail{
  .valuebox{
    width: 100%;
    .valuetitle{
      width: 100%;
      font-weight: 600;
      margin-bottom: 0;
    }
    .values{
      width: 100%;
      margin-bottom: 10px;
      display: block;
      word-break:break-word;
    }
  }
  .activeIcon{
    display: block;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #fff;
  }
}

.text-orange{
  color: #ff8a5c;
}
.link_button{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  button{
    margin:0 10px;
  }
}
.listsnapPhoto{
  padding-top: 10px;
  width: 100%;
  img{
    width: 100%;
    height: 100%;
  }
}
.snapVideo{
  padding: 10px 0;
}
#smallBtn{
  display: none;
}
.fullViewModal{
  .modal-dialog{
    max-width: 100%;
    #fullBtn{
      display: none;
    }
    #smallBtn{
      display: block;
    }
    .fullviewcontent{
      flex: 0 0 50%;
      max-width: 50%;
      padding-top: 10px;

      .listsnapPhoto{
        .col-md-6.p-0{
          &:first-child{
            padding-left: 20px !important;
          }
          &:last-child{
            padding-right: 20px !important;
          }
        }
      }
    }
    .order2content{
      padding-top: 50px;
    }
    .order2{
      order: 2;
    }
    .order1{
      order: 1;
    }
    .order3{
      order: 3;
    }
    .order4{
      order: 4;
    }
  }
}

.dataTables_filter,
.dataTables_length {
  label {
    display: flex;
    align-items: center;
  }

  input,
  select {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.95rem;
    font-size: 0.875rem;
    margin: 0 5px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, 
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{
  color: #fff !important;
  border: 1px solid #2aad68;
  background:none;
  background-color: #2aad68;
}

.table.dataTable[class*="table-"] thead th{
background-color: #ffff!important;
}
table.dataTable.hover tbody tr:hover, 
table.dataTable.display tbody tr:hover,
.table-striped tbody tr:nth-of-type(2n+1),
.table-striped tbody tr:nth-of-type(odd){
background-color: #ffff;
}
.dataTables_wrapper .dataTables_paginate{
padding: 10px 0;
}
table.dataTable tbody th, table.dataTable tbody td{
  vertical-align: middle;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover{
  color: #fff !important;
  border: 1px solid #2aad68;
  background:none;
  background-color: #2aad68;
}

.showmapbtn{
  display: block;
  width: 100%;
}
.transparent-backdrop{
  background: transparent;
  opacity: 0;
}
.transparent-backdrop.show{
  opacity: 0;
}
.mapShow_modal{
    .modal-dialog{
      box-shadow: none;
      max-width: 550px;
      float: right;
      width: 100%;
      margin-right: 25px;
    }
    agm-map {
      width: 100%;
      height: 400px;

    }
}

.mapViewmodalShow.fade{
  z-index: 9999;
  display: block;
  opacity: 1;
  .modal-dialog{
    box-shadow: none;
    max-width: 550px;
    float: right;
    width: 100%;
    margin-right: 25px;
    transform: translate(0, 10%);
  }
  agm-map {
    width: 100%;
    height: 400px;

  }
}
.pac-container{
  z-index: 9999;
}

.v-sidebar-menu .vsm-list > .vsm-item .vsm-link i{
  font-size: 19px;
  text-align:left;
  width: 34px;
  height: 34px;
  line-height: 34px;
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -17px;
  opacity: 0.3;
  transition: color 300ms
}

.content-wrapper{
  padding: 10px;
  width: 100%;
  
  .card-header{
    .card-header-title{
      i {
        margin-right: 5px;
        color: #6c757d
      }
    }
  }
  .page-body{
    padding: 10px 0;
  }
}
.card {
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%), 0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  border-width: 0;
  transition: all .2s;
}
.card-body{
  width: 100%;
}
.card-header{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-width: 1px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0.625rem;
  height: 3.5rem;
}
.card-header:first-child{
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
#checkIn{
  display: none;
}
.activeStaff{
  #checkIn{
    display: block;
  }
  #checkOut{
    display: none;
  }
}
.navTbasLink{
  margin-top: 0px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;

  li {
    display: list-item;
    text-align: -webkit-match-parent;
    a{
      color: rgb(73, 80, 87);
      background-color: transparent;
      text-decoration: none;
      display: block;
      border-radius: 0.25rem;
      padding: 0.5rem 1rem;
      cursor: pointer;
    }
    a.activeTab{
      color: rgb(255, 255, 255) !important;
      background-color: rgb(0, 123, 255) !important;
    }
  }
}

.action input[type=checkbox] {
  display:none;
}
.action input[type=checkbox] + .check
{
  background:url('/assets/images/icon/Check-out.png');
  background-size: 100% 100%;
  height: 50px;
  width: 50px;
  display:inline-block;
  padding: 0 0 0 0px;
}
.action input[type=checkbox]:checked + .check
{
  background:url('/assets/images/icon/Check-in.png');
  background-size: 100% 100%;
  height: 50px;
  width: 50px;
  display:inline-block;
  padding: 0 0 0 0px;
}

.historyTable {
  table{
    ul{
      padding-left: 0;
      list-style: none;
    }
  }
}
.destinationContent{
  margin-left: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}
.optMonitorview{
    padding-top: 10px;
  .resp-container{
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .resp-iframe{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: initial;
  }
  .resp-iframe{
    width: 99vw;
  }
}
.snapVideo{
  padding-top: 10px;
  .resp-container{
    position: relative;
    padding-bottom: 61.25%;
    padding-top: 0;
    overflow: hidden;
    margin-top: 0;
    margin-bottom: 0;
    background: #fff;
    margin-left: 15%;
  }
  .resp-iframe{
    position: absolute;
    top:0;
    left: 0;
    width: 99vw;
    height: 100%;
    transform: scale(1);
    transform: translate(-10%, 0);
    background: transparent;
  }

}
.destinationshow{
  width: 100%;
  padding-top: 40px;
  .destinationWay{
    height: 25px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: relative;
    
    img{
      position: absolute;
      top: -40px;
    }
    .startPoint{
      left: 0;
    }
    .endPoint{
      right: 0;
    }
  }
  .destiAddress{
    span{
      font-size: 12px;
      color: rgba(0, 0, 0, 0.8);
      display: block;
    }
    .endAdd{
      text-align: right;
    }
  }
}

.listFacility{
  padding:10px 20px;
  .colmd2{
    flex: 0 0 50px;
    max-width: 50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding:0 ;
    div{
      width: 100%;
      height: 100%;
      border-radius: 50%;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}

.detailMap{
  agm-map {
    width: 100%;
    height: 400px;
  }
  .map_fullview{
    height: auto;
  }
  #map{
    width: 100%;
    height: 400px;  
  }

}
.hidemap{
  display: none;
}
.ecgdata{
  padding: 10px 0;
  text-align: center;
}

.form-group.hidden {
  width: 0;
  margin: 0;
  border: none;
  padding: 0;
  height: 0;

  input, .form-control{
    width: 0;
    margin: 0;
    border: none;
    padding: 0;

  }
  
}
.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.custom-day.focused {
  background-color: #e6e6e6;
}
.custom-day.range, .custom-day:hover {
  background-color: rgb(2, 117, 216);
  color: white;
}
.custom-day.faded {
  background-color: rgba(2, 117, 216, 0.5);
}

.rangeDates{
  input{
    border:none;
  }
}
.cdk-global-overlay-wrapper, .cdk-overlay-container{
  top: 50px;
} 


.ambulanceListModalShow.fade{
  // z-index: 9999;
  display: block;
  opacity: 1;
  .modal-dialog{
    transform: translate(0, 0);
  }
}
.operationList{
  .optvaluebox{
    display: flex;
    align-items: center;
  }
}
.speedRound{
  margin:0 4px;
  height: 41px;
  width: 41px;
  box-shadow: 10px 10px 12px -8px rgba(0,0,0,0.75);
  -webkit-box-shadow: 10px 10px 12px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 12px -8px rgba(0,0,0,0.75);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color:#474443;
  color:#fff;
  border: 2px solid;
  div{
    text-align: center;
    line-height: 12px;
    font-size: 12px;

    .lastvalue{
      font-size: 8px;
    }
  }
}
.whiteText{
  color:#FFFFFF;
}
.blueText{
  color:#00c4ff;
}
.yellowText{
  color:#FFFF00;
}
.greenText{
  color: #a7ff83;
}
.grayText{
  color: #cfd9de;
}
.redText{
  color: #FF6B49;
}

.closed-sidebar .app-sidebar{
  width: 50px;
  min-width: 50px;
  flex: 0 0 50px;
}

.filteroptselect{
  .typeRounded{
    overflow: hidden;
    height: 57px;
    width: 57px;
    margin-right: 5px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #959291;
      color: #fff;
    span{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      text-align: center;
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
    input{
      position: absolute;
      top: -20px;
      visibility: hidden;
    }
  }
}
.filteroptselect{
  .typeRounded{
    input:checked ~ span{
      background-color: #474443;
      color: #fff;
    }
  }
}

#map {
  height: 100%;
  width: 100%;
 }
.monitorChart{
  #rectangle{
      width:100%;
      height:150px;                
  }
  background-color: black;
    .alert {
        padding: 20px;
        background-color: #f44336;
        color: white;
        width: 65%;
    }

    .closebtn {
        margin-left: 15px;
        color: white;
        font-weight: bold;
        float: right;
        font-size: 22px;
        line-height: 20px;
        cursor: pointer;
        transition: 0.3s;
    }

    .closebtn:hover {
        color: black;
    }

    div.smoothie-chart-tooltip {
        background: #444;
        padding: 1em;
        margin-top: 20px;
        font-family: consolas;
        color: white;
        font-size: 10px;
        pointer-events: none;
    }
    .timeMonitor{
      text-align: center;
    }
}
.resp-container {
  // height: 950px;
  margin: 0;  
  overflow: hidden;
}
.resp-iframe {
  display: block;
  background: #000;
  border: none;
  width: 100vw;
  height: 100%;
  transform: scale(0.5);
  position: absolute;
  left: -50%;
  top: -20%;
}

.mapFullview{
  position: absolute;
  width: 100%;
  height: 100%;
}
#submitLoader{
  display: none;
}
.liststaff{
  .tab-content{
    max-height: 150px;
    overflow: hidden;
    overflow-y: auto;
    margin-top: 10px;
    border: 1px solid rgba(0,0,0,0.1);
    ul{
      padding-left: 0;
      li{
        display: block;
        width: 100%;;
        text-align: left;
        padding:2px 5px;
      }
    }
  }    
}
/* width */
.tab-content::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.tab-content::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
.tab-content::-webkit-scrollbar-thumb {
  background: #C1C1C1; 
}

/* Handle on hover */
.tab-content::-webkit-scrollbar-thumb:hover {
  background: #888; 
}

.status_log{
  width: 100%;
  font-size: 13px;
  label{
    text-align: left;
    margin-bottom: 2px;
    font-weight: 600;
  }
  span{
    color: royalblue;
  }
}

.light-pink{
  background-color:#ff6565;
}
.light-orange{
  background-color:#ffb054;
}
.modalopen{
  display: none;
}
.crashModal{
  .modalopen{
    display:block;
  }
}

.crashModal{
  .modal-dialog{
    border-radius: 5px;
    box-shadow: none;
    max-width: 700px;
  }
  .modal-content{
    border-radius: 5px;
  }
  .modal-body{
    padding: 0;
    border-radius: 5px;
  }
  .close{
    height: 30px;
    width: 30px;
    padding: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $green;
    color: #fff;
    opacity: 1;
    position: absolute;
    right: -12px;
    top: -12px;
    z-index: 1;
  }
  .close:not(:disabled):not(.disabled):hover, 
  .close:not(:disabled):not(.disabled):focus{
    opacity: 1;
  }
  .crashView{
    overflow: hidden;
    border-radius: 5px;
    .crashImg{
      display: flex;
      width: 100%;
      img{
        width: 50%;
      }
    }
  }
  .staticMap{
    position: relative;
    height:380px;
    img{
      height: 100%;
      width: 100%;
      position: relative;
    }
  }
  .crashbtn{
    position: absolute;
    bottom: 15px;
    right: 0;
    left: 0;
    margin: auto;
    padding: 15px 20px;
    border-radius: 6px;
    background: rgb(102,102,102);
    color: #fff;
    border:none;
  }
  .crashDetails{
    position: absolute;
    background-color:rgba(255,101,101,0.8);
    padding: 10px;
    top: 5%;
    right: 12%;
    width: 250px;
    overflow: hidden;
    border-radius: 5px;

    span{
      display: block;
      font-size: 14px;
      line-height: 18px;
      color: #000;
    }
    .hospitalname{
      color:  $green;
      font-size: 16px;
    }
    .crash_date{
      margin-top: 5px;
      color:  rgb(2, 117, 216);
    }
  }
}
.notificaitionTable{
  table{
    img{
      width: 100px;
      min-width: 100px;
      max-width: 100px;
    }
  }
}
.visibilityRound{
  text-align: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: block;
  margin: auto;
}
.redColor{
  background-color: red;
}
.greenColor{
  background-color:  $green;
}

.btn-primary.disabled, .btn-primary:disabled{
  background: #2aad68;
  border-color: #2aad68;
}
.app-sidebar .app-header__logo{
  padding-left: 12px;
}
.logoutIcon{
  a{  
    cursor: pointer;
  }
}
// .ecg_button{
//   width: 100%;
//   text-align: center;

//   button{
//     a{
//       color: #fff;
//       text-decoration: none;

//       &:hover{
//         text-decoration: none;
//       }
//     }
//   }
// }
.right{
  float: right;
}

.ecg_box{
  width: 100%;
  height: 129px;
  position: relative;
  .minmax{
    font-size: 14px;
    color: #fff;
    text-align: left;
    position: absolute;
    padding: 0 3px;
  }
  .max_value{
    top: 2px;
  }
  .min_value{
    bottom: 2px;
  }
  .ecgValues{
    height: 100%;
    font-size: 25px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .extraecg{
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
  }
  .systitle{
    position: absolute;
    left: 0;
    right: 0;
    top: 15px;
    margin: auto;
    text-align: center;
  }
}
.progress_vertical {
  .progress{
    -webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
    transform: rotate(-90deg);
    border-radius: 0;
    background-color: transparent;
    border:1px solid #fff;
    position: absolute;
    top: 50%;
    right: -15px;
    bottom: 0;
    width: 60px;
    height: 12px;
    .bg-success{
      background-color: #fff !important;
    }
  }
}
.heart_icon{
  position: absolute;
  top: 38%;
  right: 15px;
  bottom: 0;
  color: #fff;
  font-size: 24px;
}
.actionButton{
  min-width: 150px;
  button{
    width: auto;
    margin-right: 5px;
  }
}
.alivemodalopen{
  display: none;
}
.close {
  span{
    position: relative;
    top: -2px;
  }
}
.aliveModal{
    .alivemodalopen{
      display:block;
    }
    .modal-dialog{
      border-radius: 5px;
      box-shadow: none;
      max-width: 550px;
    }
    .modal-content{
      border-radius: 5px;
    }
    .modal-body{
      padding: 0;
      border-radius: 5px;
    }
    .close{
      height: 30px;
      width: 30px;
      padding: 0;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $green;
      color: #fff;
      opacity: 1;
      position: absolute;
      right: -12px;
      top: -12px;
      z-index: 1;
    }
    .close:not(:disabled):not(.disabled):hover, 
    .close:not(:disabled):not(.disabled):focus{
      opacity: 1;
    }
    .aliveView{
      .aliveInfo{
        background-color: #8BB7C1;
        padding: 10px;
        .aliveImg{
          width: 110px;
          height: 110px;
          border-radius: 50%;
          margin: auto;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border: 4px ​solid #fff;
          img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .aliveDetail{
          .alivebox{
            width: 100%;
            label{
              width: 100%;
              font-size: 10px;
              line-height: 10px;
              color: #fff;
              margin: 0;
              text-transform: uppercase;
              opacity: 0.8;
            }
            span{
              width: 100%;
              font-size: 13px;
              line-height: 16px;
              color: #000;
              display: block;
            }
            span.redText{
              color: red;
            }
          }
        }
      }
    }
    .staticMap{
      position: relative;
      height:350px;
      img{
        height: 100%;
        width: 100%;
        position: relative;
      }
    }
    .aliveAction{
      position: absolute;
      bottom: 15px;
      right: 0;
      left: 0;
      margin: auto;
      width: 100%;
      text-align: center;
      .crashbtn{
        padding: 15px 20px;
        border-radius: 6px;
        color: #fff;
        border:none;
        margin-right: 5px;
      }
    }
}
.bluecolor{
  color: #3300ff;
}
.errorpage {
  background-image: url('../images/error.jpg');
  height: 100vh;
  width: 100%;
  background-size: 100% 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .errorInfo{
    text-align: center;

    h1{
      font-size: 70px;
      font-weight: 600;
    }
    .pageError{
      font-size: 30px;
    }
    button{
      padding: 10px 20px;
      margin-top: 10px;
    }
  }
}
.statuslabel{
  color: #fff;

  &:hover{
    color: #fff;
  }
}
.footer{
  padding: 0 10px;
  .tely_address{
    float: left;
  }
  .timers{
    float: right; 
    font-size:32px;
    color:#fff
  }
}
.statusaction{
  font-size: 20px;
  margin: 0;
  line-height: 18px;
  color: #fff;
  // background-color: #000;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 0px 30px -5px rgba(0,0,0,0.13);
    -moz-box-shadow: 0px 0px 30px -5px rgba(0,0,0,0.13);
    box-shadow: 0px 0px 30px -5px rgba(0,0,0,0.13);
}
.statusaction.nexts{
  float: right;
}
.statusaction.prevs{
  float: left;
}
.reloadbutton{
  display: block;
  margin-left: auto;
  margin-right: 15px;
  transition: 0.5;
}
.reload{
  -webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@media (min-width:1800px){
  .resp-iframe{
    width: 100vw;
    transform: scale(0.7);
    left: -28%;
    top: -10%;
  }
}

@media (min-width:1925px){
  .resp-iframe{
    width: 98vw;
  }
}

@media (min-width:1925px){
  .resp-iframe{
    width: 90vw;
    transform: scale(1);
    left: 0;
    top: 20px;
  }
}
@media (min-width:2145px){
  .resp-iframe{
    width: 80vw;
  }
}

.ageInput{
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.gm-style .gm-style-iw-c{
  transform: translate(10%, 0%); 
}

.gm-style .gm-style-iw-t::after{
  transform: translate(40%, 0%) rotate(45deg); display: none;
}   
.notification{
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle{
    color: #033C73;
    border-color: #033C73;
    background-color: transparent;
  }
  table{
    background:#fff;
  }
  table th{
    min-width:100px;

    span{
      min-height: 35px;
      height: 35px;
      display: block;
    }
    .switch-medium{
      min-height: 25px;
    }
  }
  button{
    border:none;
    font-size: 20px;
    &:hover{
      color: #033C73;
      border-color: #033C73;
      background-color: transparent;
    }
    &::after{
      display: none;
    }
  }
  .dropdown{
    position: inherit;
  }
  .dropdown-menu{
    right:0 !important;
    left:0 !important;
    width: calc(100vw - 285px);
    transform: translate(0, 50px) !important;
    background: transparent;
    box-shadow: none;
    border: none;
  }
  ul{
    list-style: none;
    padding-left: 0;
    li{
      height: 60px;
      display: block;
      min-height: 60px;
    }
  }
}
.switch-medium{
  width: 40px;
  height: 25px;
}
.switch-medium small{
  width: 25px;
  height: 25px;
}

.userprofile{
  height: 100%;
  width:100%;
  padding: 30px 10px;
  .card{
    border-radius: 15px;
    height: calc(100vh - 120px);
    .col-md-4{
      border-right: 1px solid rgba(0,0,0,0.1);
    }
  }
  .card-header{
    height:auto;
    background:#FFF ;
    padding:20px 0;
    position: relative;
    height: 100%;
    border:none;
    border-radius: 15px;
    align-items: unset;
  }
  .card-body{
    padding-top: 50px;
  }
  .profileHead{
    margin:0 auto;
    text-align: center;
    img{
      height:80px;
      width:80px;
    }
    .userTitle{
      color:#000;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .infoBox{
    margin-bottom: 10px;
    span{
      font-weight: 600;
    }
  }
  .editIcon{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: right;

    button{
      position: relative;
      right: 25px;
      font-size: 16px;
      background: #d1f3ff;
      color: #033C73;
      height: 25px;
      width: 25px;
      padding: 0;
      top: 5px;
    }
  }
  .subBtn{
    position: absolute;
    bottom:15px;
  }
}
.image-upload{
	height: 100%;
	width: 100%;

	label{
		width: 100%;
		height: 100%;
		position: relative;
		i{
			position: absolute;
			top: 0;
			right: 0;
			font-size: 14px;
			width: 25px;
			height: 25px;
			line-height: 25px;
			text-align: center;
			color: #033C73;
			background: #d1f3ff;
			border-color:#d1f3ff;
			border-radius: 50%;
		}
	}
}
.image-upload>input {
	display: none;
}
.profile_img{
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin: auto;
  display: block;

  img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.image-upload{
  .custom-file-label{
    display: none;
  }
  label{
    margin-bottom: 0;
  }
}
.h_100{
  height:100%;
}
.bg-sky-blue{
  background-color: #d1f3ff;
  color: #033C73; 
  .toast-body{
    color: #033C73;
  }
}

.webviewmodal{
  // height: 100vh;
  .modal-content{
    background:transparent;
  }
  .modal-dialog{
    box-shadow:none;
    max-width: 100%;
    height: auto;
    margin: 0;
    .resp-container{
      height: 100%;
    }
    .webIframe{
      height: 100%;
      width: 75%;
      margin: auto;
      display:block;
    }
    .modal-body{
      padding: 1rem 0;
      height: 100vh;
      .close{
        position: absolute;
        right: 10px;
        top: 5px;
        height:30px;
        width:30px;
        background-color: red;
        color:#fff;
        border-radius: 50%;

        span{
          top: -3px;
        }
      }
    }
  }
}
.socialWebview{
  .resp-container{
    padding-top: 0;
    margin-top: 0;
    iframe{
      width: 100%;
      height: 450px;
    }
  }
}


.addnoteModal{
  .modal-dialog{
    box-shadow:none;
  }
}

.confirm_modal{
  .modal-header{
    padding: 5px;
    border: none;
    background: #fff;
  }
  .modal-dialog{
    box-shadow: none;
  }
  .modal-body{
    text-align: center;
  }
  .reassignbutton{
    min-width: 150px;
    margin-top: 10px;
    text-align: center;
    button{
      width: auto;
      margin-right: 5px;
      min-width: 85px;
    }
  }
  .btn-info:hover, .btn:hover{
    color:#fff;
  }
  .btn-check:active+.btn-info, .btn-check:checked+.btn-info, .btn-info.active, .btn-info:active, .show>.btn-info.dropdown-toggle{
    color:#fff;
  }

}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 50%;
  left: 49%;
  width: 100%;
  height: 100%;
  transform: scale(2);
}
.dashboradsort{
    display: block;
    width: 100%;
    max-width: 675.7px;
    margin-bottom: 0;
    padding: 0 !important;
    position: sticky;
    top: 0;
    background-color: #fff !important;
    z-index: 1024 !important;
  .form-group{
    margin-bottom: 0;
  }
  button{
    display: block;
    margin: auto;
    background-color:transparent;
    box-shadow:none;
    color:#fff;

    &:hover{
      color:#fff;
    }
  }
  .boxcodefilter{
    width: 221px;
    max-width: 221px;
    min-width: 221px;
  }
  .speedfilter{
    width: 109px;
    max-width: 109px;
    min-width: 109px;
  }
  .statusfilter{
    width: 260px;
    max-width: 260px;
    min-width: 260px;
  }
  .searchfilter{
    width: 118px;
    max-width: 118px;
    min-width: 118px;
  }
}
.operationList{
  .fixed_headers{
    .boxcodefilter{
      width: 221px;
      max-width: 221px;
      min-width: 221px;
    }
    .speedfilter{
      width: 109px;
      max-width: 109px;
      min-width: 109px;
    }
    .statusfilter{
      width: 260px;
      max-width: 260px;
      min-width: 260px;

      .ng-dropdown-panel .scroll-host{
        height:150px;
      }
    }
    .searchfilter{
      width: 118px;
      max-width: 118px;
      min-width: 118px;
    }
  }
}
