// Dropdown

.dropdown-menu {
  box-shadow: 0 0.46875rem 2.1875rem rgba(darken($primary, 50%), .03),
  0 0.9375rem 1.40625rem rgba(darken($primary, 50%), .03),
  0 0.25rem 0.53125rem rgba(darken($primary, 50%), .05),
  0 0.125rem 0.1875rem rgba(darken($primary, 50%), .03);

  margin: $dropdown-spacer;
}

// Dropdown toggle

.dropdown-toggle {
  &::after {
    position: relative;
    top: 2px;
    opacity: .8;
    margin-left: 5px;
  }
}

.dropdown-toggle-split {
  &::after {
    margin-left: 0;
  }
}

.dropright {
  .dropdown-toggle {
    &::after {
      top: 0;
    }
  }
}

.dropdown-toggle-split {
  border-left: rgba(255, 255, 255, .1) solid 2px;
}