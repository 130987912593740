// responsive css
@media (max-width:1200px){
  .notification .dropdown-menu{
    overflow: hidden;
    overflow-x:auto;
    margin: auto;
  }
}
@media (max-width:1024px){
  .topvalueContent .blockType{
    margin-left: 5px;
  }
}
@media (max-width: 991.98px){
  .app-sidebar .app-header__logo{
    display: flex;
  }
  .app-sidebar{
    transform: translateX(0px);
  }
  .closed-sidebar .app-header .app-header__content, 
  .closed-sidebar .app-main__outer{
    padding-right: 60px;
  }
  .app-header .app-header__content{
    top:0;
    visibility: visible;
    opacity: 1;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    left: 0;
  }
  .app-header .app-header__mobile-menu{
    order: 2;
    margin-left: auto;
  }
  .topvalueContent{
    display: block;
    width: 100%;
  }
  .topvalueContent .topValue{
    justify-content: center;
  }
  .filteroptselect .typeRounded{
    height: 45px;
    width: 45px;
  }
  .filteroptselect .typeRounded span{
    font-size: 15px;
  }
  .order_2{
    order: 2;
  }
  .order_1{
    order: 1;
  }
  .app-login-box{
    .applogoinverse{
      img{
        max-height: initial !important;
        width: 150px;
      }
    }
    .w_25{
      width: 40%;
    }
  }
  .notification{
    float: right;
    position: absolute;
    top: 7px;
    right: 50px;
  }
  .notification .dropdown-menu{
    overflow: hidden;
    overflow-x:auto;
    transform:translateY(-50%) !important;
  }
}
@media (max-width: 890px){
  .sidebar_list .modal-dialog{
    max-width: 75%;
  }
  .order_2{
    order: 2;
  }
  .order_1{
    order: 1;
  }
  .snapVideo .resp-container{
    width: 28%;
  }
}
@media (max-width: 790px){
  .operationList{
    width:60%;
    overflow: auto;
  }
  .header-filter{
    .col-md-3,
    .col-md-2{
      margin-bottom: 10px;
    }
  }
  .content_filter{
    display: block;
    .filteroptselect{
      width: 100%;
    }
    .filtersearch{
      width: 100%;
    }
  }
  .form_content .form_body .form_stepper .nav-tabs{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .stafflisting_content .stafflitsbox{
    margin-bottom: 10px;
  }
  .operationList table td{
    min-width: 150px;
  }
  .app-login-box{
    .applogoinverse{
      img{
       width: 100px;
      }
      h3{
        font-size: 22px;
      }
    }
  }
  .footer{
    .timers{
      font-size: 20px;
    }
  }
  .heightcalc{
    height: calc(100vh - 30px);
  }
}
@media (max-width: 600px){
  .topvalueContent .topValue{
    font-size: 10px;
    margin-right: 0;
  }
  .topvalueContent .blockType{
    margin-left: 5px;
    padding: 4px 5px;
  }
  .sidebar_list .modal-dialog{
    max-width: 100%;
  }
  .mapViewmodalShow.fade .modal-dialog{
    float: none;
    margin-right: 0;
    margin: auto;
    max-width: 95%;
  }
  .form_content .form_button{
    padding-top: 0;
    padding-bottom: 10px;
  }
  .app-login-box .w_25{
    width:70%;
  }
  .snapVideo .resp-container{
    width: 21%;
  }
}

@media (max-width: 428px){
  .app-header__menu, .app-header__mobile-menu{
    padding: 5px;
    height: 25px;
  }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after{
    width:16px
  }
  .hamburger-box{
    width: 18px;
  }
  .app-header .app-header__content{
    width: 95%;
  }
  .footer .timers{
    font-size: 12px;
  }
  .heightcalc{
    height: calc(100vh - 21px);
  }
}

@media (max-width: 370px){
  .topvalueContent .blockType{
    margin-left: 3px;
    padding: 4px 3px;
  }
}

@media (max-width: 340px){
  .topvalueContent .blockType span{
    margin-right: 1px;
  }
  .topvalueContent .blockType{
    padding: 4px 2px;
    margin-right: 2px;
  }
}